'use client';

import Link from 'next/link';

import { Button } from '@/components/Ui/Button/Button';
import { HeaderSimple } from '@/components/Ui/HeaderSimple/HeaderSimple';
import { pagePath } from '@/constants/pagePath';

export default function Error() {
  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-page-bg to-page-bgEnd pb-28 pt-16 antialiased">
      <div className="mx-auto max-w-screen-md">
        <HeaderSimple />
        <div className="u-wrapper-side pt-4">
          <div className="rounded  p-6 text-center font-bold">
            <p className="mb-8 text-2xl">エラーが発生しました</p>
            <p className="text-sm">
              予期せぬエラーが発生しました。
              <br />
              ホームから操作をやり直してください。
            </p>
          </div>
          <Link href={pagePath.top}>
            <Button
              className="mt-20"
              buttonType="button"
              styleType="pink"
              as="span"
            >
              ホームに戻る
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
